<script>
import { mapActions } from 'vuex'

export default {
  name: 'ManagementCertificateListRelations',
  components: {
    Datatable: () => import('@/components/general/Datatable'),
    Modal: () => import('@/components/general/Modal')
  },
  data () {
    return {
      solutions: [],
      learningPaths: [],
      certificateName: null
    }
  },
  methods: {
    ...mapActions([
      'setFetching',
      'attemptListSolutionsCertificate',
      'attemptListLearningPathsCertificate',
      'attemptGetCertificateManagement'
    ]),
    close () {
      this.$router.push({ name: 'management.certificates' })
    },
    goToSolution (idSolution) {
      const elem = this.$refs['linkToSolution' + idSolution]
      elem.$el.click()
    },
    goToLearningPath (idLearningPath) {
      const elem = this.$refs['linkToLearningPath' + idLearningPath]
      elem.$el.click()
    },
    fetchCertificate () {
      return this.attemptGetCertificateManagement(this.$route.params.id).then((data) => {
        this.certificateName = data.name
        return true
      })
    },
    fetchSolutions () {
      return this.attemptListSolutionsCertificate(this.$route.params.id).then((data) => {
        this.solutions = data || []
      }).catch(() => {
        this.solutions = []
      }).finally(() => {
        return true
      })
    },
    fetchLearningPaths () {
      return this.attemptListLearningPathsCertificate(this.$route.params.id).then((data) => {
        this.learningPaths = data || []
      }).catch(() => {
        this.learningPaths = []
      }).finally(() => {
        return true
      })
    }
  },
  created () {
    this.setFetching(true)
    Promise.all([
      this.fetchCertificate(),
      this.fetchSolutions(),
      this.fetchLearningPaths()
    ]).finally(() => {
      this.setFetching(false)
    })
  }
}
</script>
<template>
  <modal
    :active="true"
    :cancel="true"
    @close="close"
  >
    <div class="center management-content">
      <span class="modal-subtitle">{{ $t('management.certificate:relations.list.title') }}</span>
      <span class="modal-subtitle certificate-name">{{ certificateName }}</span>
      <datatable
        v-if="solutions && solutions.length > 0"
        :items="solutions"
        dark
      >
        <template slot="thead">
          <tr>
            <th
              class="th"
              style="width:20%"
            >
              {{ $t('management.certificate:relations.list.type') }}
            </th>
            <th
              class="th"
              style="width:60%"
            >
              {{ $t('management.certificate:relations.list.name') }}
            </th>
            <th
              class="th align-right"
              style="width:20%"
            >
              {{ $t('management.certificate:relations.list.active') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr
            class="highlight"
            title="Acessar"
            @click="goToSolution(props.item.id)"
          >
            <td class="td">
              <span class="td-text bolder">{{ $t('management.certificate:relations.list.type.1') }}</span>
            </td>
            <td class="td">
              <span class="td-text bolder">
                <router-link
                  v-show="false"
                  :ref="'linkToSolution' + props.item.id"
                  :to="{ name: 'solutions.manage', params: { id: props.item.id, tab: 1 } }"
                  target="_blank"
                />
                {{ props.item.name }}
              </span>
            </td>
            <td class="td align-right">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
          </tr>
        </template>
      </datatable>
      <datatable
        v-if="learningPaths && learningPaths.length > 0"
        :items="learningPaths"
        dark
      >
        <template slot="thead">
          <tr>
            <th
              class="th"
              style="width:20%"
            >
              {{ $t('management.certificate:relations.list.type') }}
            </th>
            <th
              class="th"
              style="width:60%"
            >
              {{ $t('management.certificate:relations.list.name') }}
            </th>
            <th
              class="th align-right"
              style="width:20%"
            >
              {{ $t('management.certificate:relations.list.active') }}
            </th>
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr
            class="highlight"
            title="Acessar"
            @click="goToLearningPath(props.item.id)"
          >
            <td class="td">
              <span class="td-text bolder">{{ $t('management.certificate:relations.list.type.2') }}</span>
            </td>
            <td class="td">
              <span class="td-text bolder">
                <router-link
                  v-show="false"
                  :ref="'linkToLearningPath' + props.item.id"
                  :to="{ name: 'tracks.manage', params: { id: props.item.id, tab: 1 } }"
                  target="_blank"
                />
                {{ props.item.name }}
              </span>
            </td>
            <td class="td align-right">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
  </modal>
</template>

<style>
.modal-subtitle.certificate-name {
  color: #ffffffcc;
}

.align-right {
  text-align: right !important;
}

.highlight:hover{
    background-color: rgb(120, 179, 247);
    cursor: pointer;
}

a,
a:hover,
a:active {
  color: #FFFFFF;
}
</style>
